import React, { useState, useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { getsavesignature } from "../../../StateManagement/Reducers/DocumentsState";

import Error from "../../../Modules/StyledComponents/Toastify/Error";

import {
  ModalTitle,
  Modal,
  ModalContainer,
  Div,
  Text,
} from "../../StyledComponents";
import {
  CancelButton,
  ModalDialog,
  Preview,
  SaveButton,
  TextArea,
  TextBox,
} from "./Styles";

import { BsXLg } from "react-icons/bs";
import { globalColor } from "../../utils/RepeatFunctions/Schedule";

const CreateSignature = ({ show, close, success, ids, content, closesave,forIdCheck, }) => {
  // ------------------------------ State And Variables Start------------------------ //
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [ModalAnimat] = useState(true);
  const [disabledsave, setdisabledsave] = useState(false);
  const SignaturesavesignID = useSelector(
    (state) => state.Document.signaturesavesignID
  );
  const Refresh = useSelector((state) => state.Document.Refresh);
  // ------------------------------State And Variables End------------------------ //

  // ------------------------------Functions Start------------------------------- //

  const CloseSignature = () => {
    setName("");
    close();
  };
  const findAndReplaceRadioButtonValues = (htmlString) => {
    const radioButtonRegex = /\{RadioButton}~(\d+)~/g;
    const trBlockRegex = /<tr>.*?<\/tr>/gs;

    let replacedHTMLString = htmlString;

    replacedHTMLString = replacedHTMLString.replace(trBlockRegex, (trBlock) => {
      const radioButtonMatches = trBlock.matchAll(radioButtonRegex);
      const radioButtonPositions = [...radioButtonMatches].map((match) =>
        parseInt(match[1])
      );

      let count = 0;
      const replacedTrBlock = trBlock.replace(
        radioButtonRegex,
        (match, position) => {
          count++;
          const currentValue = parseInt(position);
          const replaceValue = count === currentValue ? "YES" : "-----";
          return replaceValue;
        }
      );

      return replacedTrBlock;
    });

    return replacedHTMLString;
  };

  function removeHtmlTags(htmlString) {
    const htmlRegex = /<html[^>]*>|<\/html>/gi;
    const headRegex = /<head[^>]*>|<\/head>/gi;
    const bodyRegex = /<body[^>]*>|<\/body>/gi;

    const withoutHtml = htmlString.replace(htmlRegex, "");
    const withoutHead = withoutHtml.replace(headRegex, "");
    const withoutBody = withoutHead.replace(bodyRegex, "");

    return withoutBody;
  }
  const SaveSignature = () => {
    if (name === "") {
      toast(<Error msg="Add Your Signature" />, {
        containerId: "B",
        className: "error_badge",
      });
    } else {
      const modifiedHTMLString = findAndReplaceRadioButtonValues(content);
      let result = modifiedHTMLString.replace(
        /{CheckMark}~(YES|NO)~/g,
        (match, p1) => {
          return p1 === "YES" ? "YES" : "NO";
        }
      );
      let finalContent = result.replace(/({[^{}]+}|~)/g, "");
      const resultFinal = removeHtmlTags(finalContent);

      dispatch(
        getsavesignature({
          ids: forIdCheck === "yes" ? ids : SignaturesavesignID,
          sign: name,
          temp: resultFinal,
        })
      );
      setdisabledsave(true);
    }
    closesave();
  };

  // ------------------------------Functions End-------------------------- //
  // ------------------------------UseEffect Start-------------------------- //
  useEffect(() => {
    if (Refresh === 4) {
      CloseSignature();
    }
  }, [Refresh]);
  // ------------------------------UseEffect End-------------------------- //
  return (
    <>
      <Modal show={show} height="100%" width="100%">
        <ModalContainer justifyContent="center">
          <ModalDialog animation={ModalAnimat}>
            <Div
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              margin="0px 0px 8px 0px"
            >
              <ModalTitle
                lineHight="27px"
                fontSize="20px"
                fontWeight="600"
                color="#0a1629"
              >
                Create Your Signature
              </ModalTitle>
              <BsXLg
                style={{
                  color: globalColor,
                  cursor: "pointer",
                  height: "14px",
                  width: "14px",
                }}
                onClick={close}
              />
            </Div>
            <Div>
              <Text
                fontWeight="400"
                fontSize="14px"
                color="#7d8592"
                mb="16px"
                mt="0px"
                textAlign="left"
              >
                Updatient your Information here will be applied to all future
                Signatures.
              </Text>
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#7d8592"
                mb="4px"
                mt="0px"
                textAlign="left"
              >
                Your Name
              </Text>
              <TextBox
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Text
                fontWeight="500"
                fontSize="14px"
                color="#7d8592"
                mb="0px"
                mt="0px"
                textAlign="left"
              >
                Preview
              </Text>
              <Preview>
                <TextArea as="textarea" value={name} disabled={true} />
              </Preview>
              <Div
                display="flex"
                flexDirection="row"
                justifyContent="end"
                margin="16px 0px 0px 0px"
              >
                <CancelButton
                  onClick={CloseSignature}
                  id="sig_cnl-btn"
                  className="close_active"
                >
                  Cancel
                </CancelButton>
                <SaveButton
                  disabled={disabledsave}
                  onClick={SaveSignature}
                  className="save_active"
                >
                  Save
                </SaveButton>
              </Div>
            </Div>
          </ModalDialog>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default memo(CreateSignature);
