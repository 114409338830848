import axiosInst from "../../Config";
import { Decrypt_Value } from "../EncryptDecrypt";
import axios from "axios";

const Pat_ID =
  localStorage.getItem("PID") &&
  Decrypt_Value(localStorage.getItem("PID"), "vozoportal");

export const DocumentList_Api = async (Doc_type) =>
  await axiosInst.post(`/vozoportal/getDocumentData?pid=${Pat_ID}`, Doc_type);

export const InsertDocuments_Api = async (File_data) =>
  await axiosInst.post(
    `/vozoportal/InsertDocumentData?pid=${Pat_ID}`,
    File_data
  );

export const UpdateDocument_Api = async (row_id) =>
  await axiosInst.put(`/vozoportal/UpdateDocumentData?pid=${Pat_ID}`, row_id);

export const DeleteDocument_Api = async (Data) =>
  await axiosInst.put(`/vozoportal/DeleteDocumentData?pid=${Pat_ID}`, Data);

export const DownloadDocument_Api = async (Doc_Data) =>
  await axiosInst.put(`/vozoportal/PreviewDownload?pid=${Pat_ID}`, Doc_Data);

export const PreViewDocument_Api = async (Doc_Data) =>
  await axiosInst.put(
    `/vozoportal/DownloadDocumentData?pid=${Pat_ID}`,
    Doc_Data
  );

export const ViewEdit_Api = async (Doc_Data) =>
  await axiosInst.put(
    `/vozoportal/ViewEditDocumentData?pid=${Pat_ID}`,
    Doc_Data
  );

export const SignDocument_Api = async (Doc_Data) =>
  await axiosInst.post(`/vozoportal/SaveSignatureData?pid=${Pat_ID}`, Doc_Data);

export const FirstSaveDocument_Api = async (Doc_Data) =>
  await axiosInst.post(
    `/vozoportal/FirstSaveDocumentDataPortal?pid=${Pat_ID}`,
    Doc_Data
  );

export const DeleteTempDocument_Api = async (Doc_Data) =>
  await axiosInst.put(
    `/vozoportal/DeleteTempDocumentData?pid=${Pat_ID}`,
    Doc_Data
  );

export const DeleteLabDocument_Api = async (Doc_Data) =>
  await axiosInst.put(
    `/vozoportal/DeleteLabDocumentData?pid=${Pat_ID}`,
    Doc_Data
  );

export const DownloadCredential_Api = async (Doc_Data) =>
  await axiosInst.put("/vozoportal/hgpdfupdate", Doc_Data);

export const DownloadFileApi = async (article) =>
  await axios.get(
    process.env.REACT_APP_BACKEND_FILE_ACCESS + "/healthgorilla/pdf.php",
    {
      params: article,
    }
  );

export const DownloadLabCredential_Api = async (Doc_Data) =>
  await axiosInst.put("/vozoportal/labDownloadPdf", Doc_Data);
